.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0f1629;
    color: white;
    padding: 10px 20px;
    z-index: 1000;
  }
  
  .nav-links a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .search-bar {
    padding: 5px;
    margin-right: 10px;
  }
  
  .login-button {
    background-color: #555;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  