.home-container {
    animation: fadeIn 1s;
    margin-top: 60px;
    background-color: #529be4;
    margin-left: 0%; 
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  