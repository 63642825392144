/* Container for the whole list */
.article-container {
    padding: 20px;
    background-color: #282c34;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
    margin: 20px;
  }
  
  /* Title of the list */
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Grid layout for article cards */
  .article-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  /* Styling for individual article card */
  .article-card {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .article-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Title of each article */
  .article-title {
    font-size: 20px;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Summary of the article */
  .article-summary {
    font-size: 16px;
    color: #7f8c8d;
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Read more button */
  .read-more {
    display: inline-block;
    margin-top: 10px;
    color: #2980b9;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .read-more:hover {
    color: #3498db;
  }
  