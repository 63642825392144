.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 250px;
    background-color: #0f1629;
    color: white;
    padding: 20px; 
  }
  
  .sidebar h3 {
    margin-bottom: 20px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 10px 0;
   
  }

  .list-group {

    border-radius: 20%
  }