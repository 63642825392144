.app-container {
    display: flex;
  }
  
  .content-container {
    margin-left: 250px;
    padding: 20px;
    flex: 1;
  }
  .animated-transition {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
  }

html, body, #root, .App {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #0f1629; /* Set the full-page background color */
    color: white;              /* Optional: Set text color for contrast */
    font-family: Arial, sans-serif;  /* Optional: Set a default font */
  }
  
  .App {
    text-align: center;
    background-color: #282c34;
    display: flex;
  
  }
  
  .App-logo {
    height: 15vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .left {
    float:left;
  }
  
  .right {
    float:right
  }