.blog-container {
    animation: fadeIn 1s;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  

  /* General Container */
.articles-container {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #529be4;
  display: grid;
}

/* Heading */
.articles-heading {
  text-align: center;
  font-size: 2rem;
  color: #444;
  margin-bottom: 20px;
}

/* Horizontal Card */
.article-horizontal-card {
  display: grid;
  justify-content: space-between;
  align-items: baseline;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.article-horizontal-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Content Section */
.article-content {
  max-width: 70%;
}

.article-title {
  font-size: 1.4rem;
  margin: 0;
  color: #333;
  line-height: 1.4;
}

.article-author {
  font-size: 0.9rem;
  color: #251f1f;
  margin-top: 5px;

}

/* Action Section */
.article-action {
  text-align: right;
}

.read-more-btn {
  padding: 8px 16px;
  background-color: #1a73e8;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.read-more-btn:hover {
  background-color: #0056b3;
}

.side {
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 20px; /* Add space between image and text */
}

